import WebGL from './WebGL';
import { PerspectiveCamera } from 'three';
import lottie from 'lottie-web';
import BackgroundLoader from './BackgroundLoader';
import fragment from '../shaders/home/fragment.glsl';
import vertex from '../shaders/home/vertex.glsl';

class Home {
    constructor() {
        this.namespace = document.getElementById('page-welcome');    
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;

        this.setupWindowEvents();
        this.setupListeners(); 
        this.introAnimation();
    } 

    setupListeners(){
        window.addEventListener('wheel', this.scrollWheelHandler.bind(this));
    }

    introAnimation() {
        lottie.loadAnimation({
            container: document.querySelector('.introAnimation'),
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: '../static/animations/home.json' 
          });
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {

    }

    scrollWheelHandler(e) {
     
    }


    destroy() {
        window.removeEventListener('wheel', this.scrollWheelHandler);
    }
}
export default Home;