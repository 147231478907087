import WebGL from './WebGL';
import { PerspectiveCamera } from 'three';
import lottie from 'lottie-web';
import BackgroundLoader from './BackgroundLoader';
import fragment from '../shaders/home/fragment.glsl';
import vertex from '../shaders/home/vertex.glsl';


class About {
    constructor() {
        this.namespace = document.getElementById('page-about');    
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;

        this.setupWindowEvents();
        this.setupListeners(); 
    }

    setupListeners(){
        window.addEventListener('wheel', this.scrollWheelHandler.bind(this));
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {

    }

    scrollWheelHandler(e) {
       
    }


    destroy() {
        window.removeEventListener('wheel', this.scrollWheelHandler);
    }
}
export default About;