import WebGL from './WebGL';
import { PerspectiveCamera } from 'three';
import lottie from 'lottie-web';
import BackgroundLoader from './BackgroundLoader';
import fragment from '../shaders/home/fragment.glsl';
import vertex from '../shaders/home/vertex.glsl';

class About {
    constructor() {
        this.namespace = document.getElementById('page-radio');    
        this.tracks = [];
        this.isPlaying = false;
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;

        this.setupWindowEvents();
        this.setupListeners(); 

        this.players = document.querySelectorAll('iframe');

        this.players.forEach(player => {
            this.tracks.push(SC.Widget(player));
        });

        const playButton = document.getElementById('play-radio');

        playButton.addEventListener('click', () => {
            if (!this.isPlaying) {
                this.tracks[0].play();
                this.isPlaying = true;
                playButton.classList.add('isPlaying');
            } else {
                this.tracks[0].pause();
                this.isPlaying = false;
                playButton.classList.remove('isPlaying');
            }
        });

        lottie.loadAnimation({
            container: document.querySelector('.eq'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '../static/ani/audio.json' 
        });
    }

   

    setupListeners(){
        window.addEventListener('wheel', this.scrollWheelHandler.bind(this));
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
     
    }

    scrollWheelHandler(e) {
 
    }


    destroy() {
        window.removeEventListener('wheel', this.scrollWheelHandler);
    }
}
export default About;