
class Contact {
    constructor() {
        this.namespace = document.getElementById('page-contact');    
     
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;
        this.form = document.getElementById('contact-form');
        this.thankyou = document.getElementById('thankyou');
        this.form.addEventListener('submit', this.handleSend.bind(this));
    }

   
    handleSend(e) {
        e.preventDefault();
        
        const form = new FormData(this.form);
        const name = form.get("name");
        const email = form.get("email");
        const message = form.get("message");
        const phone = form.get("phoneNumber");
        const reason = form.get("reason");

        if (!name || !phone || !message || !email || !reason) {
            return;
        }
        
        const send = async () => {    
            await fetch(
                `/.netlify/functions/sendContactForm`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    name,
                    email,
                    phone,
                    from: "website@nicolemoudaber.com",
                    subject: "Contact Form Submission",
                    reason, 
                    message,
                  }),
                }
              );
        };

        send();
        this.form.style.display = "none";
        this.thankyou.style.display = "block";
        return false;
    }
 
}
export default Contact;