import WebGL from './WebGL';
import { PerspectiveCamera } from 'three';
import BackgroundLoader from './BackgroundLoader';
import fragment from '../shaders/home/fragment.glsl';
import vertex from '../shaders/home/vertex.glsl';
import moment from 'moment';

const API = 'https://rest.bandsintown.com/artists/Nicole%20Moudaber/events?app_id=squarespace-conch-grey-f2c4&date=upcoming';

class Events {
    constructor() {
        this.namespace = document.getElementById('page-upcomingevents');    
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;

        this.listingsWrapper = document.querySelector('.listings');

        this.setupWindowEvents();
        this.setupListeners(); 
        //this.getListings();
    }

    getListings() {
        fetch(API)
        .then(response => response.json())
        .then(data => {
            this.renderListings(data);
        });
    }

    renderListings(data) {
        data.forEach(event => {
            this.listingsWrapper.append(this.createListing({
                venueName: event.venue.name,
                venueCity: event.venue.city,
                venueStreet: event.venue.street_address,
                venueCountry: event.venue.country,
                date: event.datetime,
                url: event.url,
                ticket: event.offers[0] && event.offers[0].url
            }));
        });
    }

    createListing(event) {
        const el = document.createElement('li');
        el.innerHTML = `
            <div>${moment(event.date).format('MMM')} ${moment(event.date).format('DD')}</div>
            <div>${event.venueName}</div>
            <div class="country">${event.venueCountry}</div>
            <div>
                <a href="${event.ticket}" target="_blank"><button>Tickets</button></a>
                <a href="${event.url}" target="_blank"><button class="info">Info</button></a>
            </div>          
        `;
        return el;
    }

    setupListeners(){
        window.addEventListener('wheel', this.scrollWheelHandler.bind(this));
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
        
    }

    scrollWheelHandler(e) {
       
    }


    destroy() {
        window.removeEventListener('wheel', this.scrollWheelHandler);
    }
}
export default Events;

