import barba from '@barba/core';
import lottie from 'lottie-web';
import Lenis from '@studio-freight/lenis'
import Home from './Home';
import About from './About';
import Radio from './Radio';
import Events from './Events';
import Music from './Music';
import Contact from './Contact';
import imagesLoaded from 'imagesloaded';
import ls from 'local-storage';

class Site {
    constructor() {
        this.setupRouting.bind(this);
        this.setupRouting();      
        this.body = document.querySelector('body');
        this.pages = [];
        this.footer = document.getElementById('footer');
        this.logoNav = document.querySelector('.logo-nav');
        this.signupPopup = document.getElementById('signup');
        this.signupClose = document.querySelector('.btn-close');
        this.signupShown = ls.get('signupShown');
  
        this.initPage();
        this.setupLottie();
    }

    setupRouting() {
      const _self = this;

      barba.init({
          schema: {
              prefix: 'data-site',
              wrapper: 'app'
          },
          views: [{
            namespace: 'site',
            beforeLeave() {
              _self.destroyPage();
            },
            afterEnter() {
              window.scrollTo({
                  top: 0
              });
            }
          }]
      });

      barba.hooks.before(() => {
        document.querySelector('.site-wrapper').classList.add('is-leaving');
        document.querySelector('.preloader').classList.add('show');
      });

      barba.hooks.after(() => {
        this.initPage();

        this.nav.classList.remove('is-active');
        this.body.classList.remove('is-locked');
        
        setTimeout(() => {
          document.querySelector('.site-wrapper').classList.remove('is-leaving');
          document.querySelector('.preloader').classList.remove('show');
        }, 300);
      });
    }

    toggleMenu() {
      this.body.classList.add('is-locked');
      this.nav.classList.add('is-active');
    }

    setupLottie() {

        const aniElement = document.getElementById('intro-logo');

        var animation = lottie.loadAnimation({
            container: aniElement,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: '/static/ani/logo_intro.json'
        });

        animation.setSpeed(2.5);

        animation.addEventListener('complete', () => {
            this.splash.classList.remove('is-active');

            setTimeout(() => {
                this.splash.classList.add('locked');
            }, 200);
        });

        lottie.loadAnimation({
            container: this.logoNav,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/static/ani/logo_nav.json'
        });

    }

    loadPages(){
      this.pages.push(new Home());
      this.pages.push(new About());
      this.pages.push(new Radio());
      this.pages.push(new Events());
      this.pages.push(new Music());
      this.pages.push(new Contact());
    }

    destroyPage() {
      this.pages.forEach(page => {
        page.destroy();
      });
    }

    initPage() {
      this.loadPages();
      this.setupListeners();   

      if (!this.signupShown) {
        setTimeout(() => {
          this.signupPopup.classList.add('active');
        }, 2000);
      }
      
      if (this.signupClose) {
        this.signupClose.addEventListener('click', () => {
          this.signupPopup.classList.remove("active");
          this.signupShown = true;
          ls.set('signupShown', 'true');
        });
      }

      this.subscribeForm = document.querySelector('.subscribeForm');
      this.subscribeFormPopup = document.querySelector('.subscribeFormPopup');

      if (this.subscribeForm) {
        this.subscribeForm.addEventListener('submit', this.submitForm.bind(this));
        this.subEmail = document.querySelector('.subEmail');
      }

      if (this.subscribeFormPopup) {
        this.subscribeFormPopup.addEventListener('submit', this.submitForm.bind(this));
        this.subEmailPopup = document.querySelector('.subEmailPopup');
      }

      this.thankYou = document.getElementById('sub-thanks');
      this.thankYouPopup = document.getElementById('sub-thanks-popup');

    }

    submitForm(e) {
      e.preventDefault();

      if (!this.subEmail.value && !this.subEmailPopup.value) {
          return false;
      }

      const send = async () => {    
        this.thankYou.style.display = 'block';
        this.thankYouPopup.style.display = 'block';

        await fetch(
            `/.netlify/functions/signup`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                email: this.subEmail.value || this.subEmailPopup.value,
              }),
            }
          );

          
          ls.set('signupShown', 'true');
      };

      send();
      return false;
  }

    setupListeners() {
      this.menuButton = document.querySelector('.btn-menu');
      this.nav = document.querySelector('nav');

      this.menuButton.addEventListener('click', () => {
        this.toggleMenu();
      });
    }
}

export default Site;