import * as T from 'three';

const device = {
  width: window.innerWidth,
  height: window.innerHeight,
  pixelRatio: window.devicePixelRatio
};

const raycaster = new T.Raycaster();
const pointer = new T.Vector2();

function onPointerMove( event ) {
	pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
	pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
}

window.addEventListener( 'pointermove', onPointerMove );

export default class WebGL {

  constructor(args) {
    this.canvas = args.canvas;
    this.scene = new T.Scene();
    this.camera = args.camera;
    this.fragment = args.fragment || null;
    this.vertex = args.vertex || null;
    this.renderQueue = [];
    this.intersectCords = {x: -1, y: -1};
    this.fullScreen = args.fullScreen !== undefined ? args.fullScreen : true;

    this.scene.add(this.camera);

    this.renderer = new T.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: true
    });

    this.renderer.setSize(device.width, device.height);
    this.renderer.setPixelRatio(Math.min(device.pixelRatio, 2));
    this.clock = new T.Clock();

    this.getIntersectCords = this.getIntersectCords.bind(this);

    this.render();
    this.setResize();
  }

  rayCaster() {
    raycaster.setFromCamera( pointer, this.camera );
    
	  const intersects = raycaster.intersectObjects( this.scene.children );
    this.intersectCords = intersects.length ? intersects[0] : {uv: {x: 0, y: 0}};
  }

  render() {
    this.renderQueue.forEach((item) => {
      item.render();
    });
    
    this.rayCaster();
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.render.bind(this));
  }

  getIntersectCords() {
    return this.intersectCords;
  }

  setResize() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  getScene() {
    return this.scene;
  }

  addToRenderQueue(item) {
    this.renderQueue.push(item);
  }

  removeFromRenderQueue(item) {
    this.renderQueue = this.renderQueue.filter(i => i !== item);
  }

  onResize(scene) {
   
  }

  handleResize() {

      if (this.fullScreen) {
        device.width = window.innerWidth;
        device.height = window.innerHeight;
      } else {
        device.width = this.canvas.offsetWidth;
        device.height = this.canvas.offsetHeight;
      }

      this.camera.aspect = device.width / device.height;
      this.camera.fov = 2 * Math.atan((window.innerHeight / 2) / this.camera.position.z) * 180 / Math.PI;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(device.width, device.height);
      this.renderer.setPixelRatio(Math.min(device.pixelRatio, 2));

      this.onResize(this.scene);
  }
}
